const Utils = {
  dashToCamelCase(str) {
    return str.replace(/(-\w)/g, value => value[1].toUpperCase())
  },
  dashToSpace(str) {
    return str.replace(/(-\w)/g, value => ' ' + value[1].toUpperCase())
  },
  replaceSubStr(str, from, to) {
    const rx = new RegExp(from, 'g');
    return str.replace(rx, (val, idx) => {
      const isFirst = idx === 1;
      const nextIsSingleChar = str[idx + 2] === from;
      const prevIsSingleChar = str[idx - 2] === from;
      if (nextIsSingleChar) {
        return to;
      } else if (prevIsSingleChar || isFirst) {
        return ' ';
      } else {
        return to;
      }
    });
  },
  addEl(selector, event, ...cbs) {
    const ui = {};
    let key;
    if (selector instanceof Node)
      ui[Utils.dashToCamelCase(selector.nodeName.substring(1))] =
        selector;
    else {
      const isIdOrClass = /\.|#/.test(selector);
      key = Utils.dashToCamelCase(
        isIdOrClass ? selector.substring(1) : selector
      );
      const el = document.querySelector(selector);
      !ui[key] && el && (ui[key] = el);
    }
    ui[key] && event &&
      (key ? ui[key] : selector).addEventListener(event, (evt) => {
        let param = evt;
        cbs.forEach((cb) => (param = cb(param)));
      });
    return ui;
  },
  uiHandler(...args) {
    try {
      return args.reduce((acc, item) => ({ ...acc, ...Utils.addEl.call(null, ...item) }), {});
    } catch (error) {
      debugger;
    }
  },

  doter(target, title) {
    const dots = ["⠟", "⠻", "⠽", "⠾", "⠷", "⠯"];
    let i = 0;
    const isActive = {};
    let interval;
    const run = () => {
      dispatch(text(dots));
      interval = setInterval(
        () =>
          Reflect.ownKeys(isActive).length
            ? dispatch(text(dots))
            : (() => {
              interval = clearInterval(interval);
              dispatch(title);
            })()
        ,
        120
      );
    };
    const text = (d) => `<span style="position:relative; top:1px; right: 2px;">${d[++i % d.length]}</span> ${title}`;
    const dispatch = (detail) => target.innerHTML = detail;
    return {
      start: (id) => {
        !Reflect.ownKeys(isActive).length && run();
        isActive[id] = true;
        setTimeout(() => {
          if (isActive[id]) {
            console.warn(`request ${id.toString()} timeout`);
            stop(id);
          }
        }, 10000);
      },
      stop: (id) => {
        setTimeout(() => delete isActive[id], 250);
      },
    };
  }
};

export default Utils;
